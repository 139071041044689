window.angular.module('MyHippoProducer.Components').directive('sideNavContainer', function () {
    return {
        restrict: 'E',
        scope: {},
        transclude: true,
        templateUrl: 'components/side-nav/container/container.html',
        controller: function($scope, EndorsementService, PolicyService, $state, $log, $stateParams) {
            $scope.isEditMode = PolicyService.isEditMode;
            $scope.startEndorsement = () => EndorsementService.startEndorsement()
                .then(() => $state.go('portal.policyDetailsV2', _.assign({}, $stateParams, { mode: 'edit' })))
                .catch((err) => $log.info('Not starting endorsement', err));
        }
    };
});
